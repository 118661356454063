import React from 'react'
import IPhone_11 from '../../../Models/iphone_11'
import IPhone_12 from '../../../Models/iphone_12'
import Macbook from '../../../Models/macbook'
import IPad from '../../../Models/ipad'

export default ({currentDevice, selected}) => {
    let frame = currentDevice.frame

    if(frame === 'iPhone 11'){
        return <IPhone_11 currentDevice={currentDevice} />
    }
    else if(frame === 'iPhone 12'){
        return <IPhone_12 currentDevice={currentDevice} />
    }
    else if(frame === 'MacBook'){
        return <Macbook currentDevice={currentDevice} />
    }
    else if(frame === 'iPad'){
        return <IPad currentDevice={currentDevice} />
    }
}