import React, { useEffect } from 'react'
import Maker from '../../components/Makers/3D/Maker/Maker'
import ChromeModal from '../../components/Modals/ChromeModal/ChromeModal'

export default () => {
    return(
        <div>
            <ChromeModal />
            <Maker />
        </div>

    )
}