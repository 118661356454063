import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { extend, useThree } from "react-three-fiber";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { setDragging } from "../../../../../../slices/threed-canvas-screenshot";

extend({ DragControls });

export default (props) => {
  const [controls, setControls] = React.useState(null);
  // const [dragging, setDragging] = React.useState(false)
  const controlRef = useRef();
  let dispatch = useDispatch();
  let control = useSelector((state) => state.ScreenshotsCanvasThreeD.present.control);
  const dragging = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.dragging
  );

  const {
    camera,
    gl: { domElement },
  } = useThree();

  React.useEffect(() => {
    if (!dragging && props.hovered.object && !props.hovered.resize) {
      setControls(
        <>
          <dragControls
            enabled={control === "objects" ? true : false}
            ref={controlRef}
            args={[
              props.hovered.object && !props.hovered.resize
                ? [props.hovered.object]
                : [],
              camera,
              domElement,
            ]}
            transformGroup={props.hovered.group}
          />
        </>
      );
    }
  }, [props.hovered, dragging, control]);

  React.useEffect(() => {
    if (controlRef.current) {
      controlRef.current.addEventListener("dragstart", () => {
        dispatch(setDragging(true));
      });
      controlRef.current.addEventListener("dragend", (e) => {
        if (props.hovered.object) {
          let setPosition = props.hovered.object.userData.setPosition;
          let position = props.hovered.object.position;
          setPosition({ ...position });
        }
        dispatch(setDragging(false));
      });
      controlRef.current.addEventListener("hoveroff", (e) => {
        if (control === "objects" && dragging) {
          if (props.hovered.object) {
            let setPosition = props.hovered.object.userData.setPosition;
            let position = props.hovered.object.position;
            setPosition({ ...position });
          }
          dispatch(setDragging(false));
        }
      });
    }
  }, [controls, controlRef, props.hovered, dragging]);

  return controls;
};
