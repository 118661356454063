import TWEEN from '@tweenjs/tween.js'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFrame, useThree } from 'react-three-fiber'
import * as THREE from 'three'
import { setPlayback } from '../../../../../../slices/threed-canvas-screenshot'


export default ({onCreated}) => {
  let dispatch = useDispatch()
    const [tweens, setTweens] = React.useState(null)
    const [zooms, setZooms] = React.useState(null)
    const ref = React.useRef()
    let playback = useSelector(state => state.ScreenshotsCanvasThreeD.present.playback)
    let recording = useSelector(state => state.ScreenshotsCanvasThreeD.present.recording.ready)
    let positions = useSelector(state => state.ScreenshotsCanvasThreeD.present.camera.positions)
    let cameraZooms = useSelector(state => state.ScreenshotsCanvasThreeD.present.camera.zooms)
    
    let checkpoints = useSelector(state => state.ScreenshotsCanvasThreeD.present.checkpoints)
    let time = React.useRef(TWEEN.now())

    const { setDefaultCamera } = useThree()
    // Make the camera known to the system
    React.useEffect(() => {
        onCreated(ref.current)
        void setDefaultCamera(ref.current)
    }, [])


    useFrame((state, delta) => {
        if(delta && tweens && zooms && playback && !recording){  
          time.current += delta * 1000
          TWEEN.update(time.current)

        }
        else if(tweens && zooms && recording && state.invalidateFrameloop && state.frames === 1){
          TWEEN.update(time.current)
          time.current += 1000/30
        }
      },1)

  
      React.useEffect(() => {
        if(!playback && !recording && tweens && zooms){
          tweens[0].stop()
          zooms[0].stop()
          let camera = ref.current
          camera.position.set(positions[checkpoints.current].x, positions[checkpoints.current].y, positions[checkpoints.current].z)
          camera.zoom = cameraZooms[checkpoints.current]
          camera.lookAt(0,0,0)
          camera.updateProjectionMatrix()
        }
        else if(tweens && zooms && (playback || recording)){
          time.current = TWEEN.now()
          tweens[0].start()
          zooms[0].start()

        }
      }, [ref.current,checkpoints.current, playback,recording, tweens, zooms, positions, cameraZooms])


React.useEffect(() => {
  function createTweens(positionsAll, checkpointsAll){
    let slicesZooms = cameraZooms.slice(checkpointsAll.current)
    let checkpoints = checkpointsAll.durations.slice(checkpointsAll.current)
    let positions = positionsAll.slice(checkpointsAll.current)
    let transitions = checkpointsAll.transitions.slice(checkpoints.current)

    if(checkpoints.length > 1){ // If it is a video
      TWEEN.removeAll()

      setZooms(null)
      setTweens(null)
      let tweens = []
      let zooms = []
      let camera = ref.current

      for(let i = 0; i < checkpoints.length - 1; i++){
        let transition = transitions[i].split('.')



        let angle = {
          value: 0,
        };
        let angleEnd = {
          value: 0,
        };

        let startSphereVector = new THREE.Vector3(
            positions[i].x,
            positions[i].y,
            positions[i].z
        )

        let endSphereVector = new THREE.Vector3(
            positions[i + 1].x,
            positions[i + 1].y,
            positions[i + 1].z
        )

        let axis = startSphereVector.clone().cross(endSphereVector).normalize()

        angle.value = 0;
        angleEnd.value = startSphereVector.angleTo(endSphereVector);

        let zoom = {
          value: slicesZooms[i]
        }
        let zoomEnd = {
          value: slicesZooms[i + 1]
        }

        let tween = new TWEEN.Tween(angle).to(angleEnd, checkpoints[i + 1] * 1000)
        .easing(TWEEN.Easing[transition[0]][transition[1]])
        .onUpdate(
          () => {
            camera.position.copy(startSphereVector).applyAxisAngle(axis, angle.value)
            camera.lookAt(0,0,0)
          }
        )


        let zoomTween = new TWEEN.Tween(zoom).to(zoomEnd, checkpoints[i + 1] * 1000)
        .easing(TWEEN.Easing[transition[0]][transition[1]])
        .onUpdate(
          () => {
            camera.zoom = zoom.value
            camera.updateProjectionMatrix()
          }
        ).onComplete(() => {
          if(i === checkpoints.length - 2){
            dispatch(setPlayback(false))
            camera.position.set(positions[0].x, positions[0].y, positions[0].z)
            camera.zoom = slicesZooms[0]
            camera.lookAt(0,0,0)
            camera.updateProjectionMatrix()
          }
        })

        if(zooms.length > 0){
          zooms[i - 1].chain(zoomTween)
        } 
        zooms.push(zoomTween)

        if(tweens.length > 0){
          tweens[i - 1].chain(tween)
        } 
        tweens.push(tween)
      }
      setZooms(zooms)
      setTweens(tweens)
    }
  }
  if(ref.current && positions && checkpoints){
    createTweens(positions, checkpoints)
  }
}, [ref.current, positions, checkpoints, cameraZooms])

    return <perspectiveCamera ref={ref} 
    onUpdate={self => self.updateProjectionMatrix()}
    zoom={cameraZooms[checkpoints.current]}
    position={[positions[checkpoints.current].x,positions[checkpoints.current].y,positions[checkpoints.current].z]} 
    fov={20} near={1} far={1000}/>
  }