import React from 'react'
import CubeSvg from '../../../../../assets/svg/cube.svg'
import CameraSvg from '../../../../../assets/svg/film.svg'
import * as styles from './controls.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setControl } from '../../../../../slices/threed-canvas-screenshot'

export default () => {
    let dispatch = useDispatch()
    let control = useSelector(state => state.ScreenshotsCanvasThreeD.present.control)

    return(
        <div className={styles.container}>
            <CubeSvg onClick={() => dispatch(setControl('objects'))} className={control === 'objects' ? styles.active : null}/>
            <CameraSvg onClick={() => dispatch(setControl('camera'))} className={control === 'camera' ? styles.active : null}/>
        </div>
    )
}