import React from "react";
import { useSelector } from "react-redux";

import * as styles from "./displayedtab.module.scss";
import BackgroundTab from "../BackgroundTab/BackgroundTab";
import DeviceTab from "../DeviceTab/DeviceTab";
import AdvancedTab from "../AdvancedTab/AdvancedTab";
import ImageTab from "../ImageTab/ImageTab";
import TextTab from "../TextTab/TextTab";

export default () => {
  let id = useSelector(state => state.ScreenshotsCanvasThreeD.present.selectedId);
    let integerId = id.replace(/\D/g, "")
  return (
    <div className={styles.mainContainer}>
      {id === "advanced" ? (
        <AdvancedTab />
      ) : id.includes("device") ? (
        <DeviceTab id={integerId} />
      ) : id.includes("image") ? (
        <ImageTab id={integerId} />
      ) : id.includes("text") ? (
        <TextTab id={integerId} />
      ) : (
        <BackgroundTab />
      )}
    </div>
  );
};
