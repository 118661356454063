import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { saveAs } from "file-saver";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFrame, useThree } from "react-three-fiber";
import { toast } from "react-toastify";
import API from '../../../../../../firebase/api';
import {
  cancelRecording,
  setRecordingProgress,
  setRecordingText,
  startRecording
} from "../../../../../../slices/threed-canvas-screenshot";
const ffmpeg = createFFmpeg({log: false});




export default () => {
  const { invalidate, gl } = useThree();
  let dispatch = useDispatch();

  let currentTime = useRef(0)
  let numberOfFrames = useRef(0)
  let running = useRef(false)

  let recordingScale = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.recording.scale
  );
  let readyRecording = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.recording.ready
  );
  let durations = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.checkpoints.durations
  );
  let dimensions = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.dimensions
  );

  let totalDuration = durations.reduce((a, b) => a + b, 0);

  function toEven(number){
    return 2 * Math.round(number / 2);
  }

  useEffect(() => {
    if(recordingScale){
      async function initFFMPEG(){
        // Check that inputs are not more than 2GB
        let snapshot = gl.domElement.toDataURL()
        let head = 'data:image/png;base64,';
        var imgFileSize = Math.round((snapshot.length - head.length)*3/4) ;
        let totalBytes = imgFileSize * totalDuration * 30
        let totalGB = totalBytes / (1024**3)
        if(totalGB >= 1.8){
          toast.error('The video is too large. Try reducing dimensions or duration.')
          return
        }
        dispatch(
          setRecordingProgress(0)
        );
        dispatch(
          setRecordingText('Initializing your animation...')
        );
        if(!ffmpeg.isLoaded()){
          await ffmpeg.load()
          ffmpeg.setProgress(({ ratio }) => {
            dispatch(
              setRecordingProgress(Math.floor(ratio*100))
            );
          });
        }
        let ratio =
        (dimensions.width * recordingScale) / gl.domElement.clientWidth;
      gl.setPixelRatio(ratio);
  
      gl.domElement.style.pointerEvents = "none";
      document.body.style.pointerEvents = "none";
        dispatch(startRecording())
        
      }
      initFFMPEG()
    }
  }, [recordingScale, dimensions, gl, totalDuration, ffmpeg])



  useFrame(async (state, delta) => {
    let { scene, camera, gl, invalidateFrameloop } = state
    gl.render(scene, camera)
    if (readyRecording && invalidateFrameloop && state.frames === 1 && !running.current) {
      if (currentTime.current > totalDuration) {
        running.current = true
        dispatch(
          setRecordingText('Rendering your animation...')
        );
        let width = toEven(dimensions.width * recordingScale)
        let height = toEven(dimensions.height * recordingScale)
        let ffmpegDimensions = `${width}x${height}`

        await ffmpeg.run('-framerate', '30', '-pattern_type', 'glob', '-i', '*.png', '-c:v', 'libx264', '-pix_fmt', 'yuv420p', '-preset', 'ultrafast', '-s', ffmpegDimensions, 'out.mp4');
        const data = ffmpeg.FS('readFile', 'out.mp4');

        for (let i = 0; i < numberOfFrames.current; i ++) {
          const num = `00000${i}`.slice(-6);
          ffmpeg.FS('unlink', `tmp.${num}.png`);
        }

        let blob = new Blob([data.buffer])
        if(blob.size > 100){
          saveAs(blob, "out.mp4");
          API.onUserDownload('3d', false)
        }
        
        ffmpeg.FS('unlink', `out.mp4`);

        dispatch(cancelRecording());
        running.current = false
        currentTime.current = 0;
        numberOfFrames.current = 0
        gl.setPixelRatio(window.devicePixelRatio);

        gl.domElement.style.pointerEvents = "auto";
        document.body.style.pointerEvents = "auto";
        
      } else {
          let data = await fetchFile(gl.domElement.toDataURL());
          const num = `00000${numberOfFrames.current}`.slice(-6);
          // use ffmpeg
          ffmpeg.FS('writeFile', `tmp.${num}.png`, data);
          currentTime.current += 1 / 30;
          numberOfFrames.current++;
          dispatch(
            setRecordingProgress(Math.floor(currentTime.current*100/totalDuration))
          );
          invalidate();
      }
    }
  }, 2);

  return null;
};
