import React from 'react'
import { useDispatch } from 'react-redux'
import { setActive } from '../../../../slices/threed-canvas-screenshot'
import { setActiveCanvas } from '../../../../slices/user-slice'
import MakerBar from '../../../Common/MakerBar/MakerBar'
import Canvas from './Canvas/Canvas'
import Controls from './Controls/Controls'
import * as styles from './maker.module.scss'
import ProgressBar from './ProgressBar/ProgressBar'
import DisplayedTab from './Tabs/DisplayedTab/DisplayedTab'
import TimeFrameTab from './TimeFrameTab/TimeFrameTab'

export default () => {
    let dispatch = useDispatch()
    
    React.useEffect(() => {
        dispatch(setActive(true))
        dispatch(setActiveCanvas('3d'))
      }, [])
    return(
        <>
        <div className={styles.phoneDiv}>
          <span>Unavailable on small screens</span>
          <span>Please use a tablet or a desktop to access our maker</span>
        </div>
        <div className={styles.makerMain}>
        <MakerBar />
        <div className={styles.toolContainer}>
            <div className={styles.leftSide}>
                <DisplayedTab />
            </div>
            <div className={styles.rightSide}>
                <div className={styles.canvasWrapper}>
                    <Controls />
                    <div id={'stage-parent'} className={styles.stageParent}>
                        <Canvas />
                    </div>
                    <ProgressBar />
                </div>
                <TimeFrameTab />
            </div>
        </div>
        </div>
        </>
    )
}