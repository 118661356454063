// extracted by mini-css-extract-plugin
export const container = "timeframetab-module--container--2GOWa";
export const topLevel = "timeframetab-module--top-level--2m4Dz";
export const time = "timeframetab-module--time--5UdHX";
export const playback = "timeframetab-module--playback--1yWyh";
export const lowLevel = "timeframetab-module--low-level--1-YU8";
export const checkpointsDiv = "timeframetab-module--checkpoints-div--QM66q";
export const checkpoint = "timeframetab-module--checkpoint--3NW5o";
export const deleteCheckpoint = "timeframetab-module--delete-checkpoint--23ote";
export const checkpointActive = "timeframetab-module--checkpoint-active--35IGg";
export const checkpointDuration = "timeframetab-module--checkpoint-duration--3wpgv";
export const addCheckpoint = "timeframetab-module--add-checkpoint--ksQti";
export const createAnimationContainer = "timeframetab-module--create-animation-container--3_47g";