import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './progressbar.module.scss'
import { cancelRecording } from '../../../../../slices/threed-canvas-screenshot'
import { store } from '../../../../../../configureStore'

export default () => {
    // let renderer = store.getState().ScreenshotsCanvasThreeD.present.renderer
    let progress = useSelector(state => state.ScreenshotsCanvasThreeD.present.recording.progress)
    let text = useSelector(state => state.ScreenshotsCanvasThreeD.present.recording.text)
    // let dispatch = useDispatch()

    return(
        <div className={styles.container} style={{display: progress === null ? 'none' : 'flex'}}>
            <h3>{text}</h3>
            <h4>{progress} %</h4>
            <div className={styles.progressBar}>
                <div style={{width: `${progress}%`}} />
            </div>
            <span>This process can take a long time. Please be patient.</span>
            {/* <button onClick={() => {
                    renderer.domElement.style.pointerEvents = 'auto'
                    document.body.style.pointerEvents = 'auto'
                renderer.setPixelRatio(window.devicePixelRatio)
                dispatch(cancelRecording())
            }} className={styles.cancelButton}>Cancel</button> */}
        </div>
    )
}