import TWEEN from "@tweenjs/tween.js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFrame } from "react-three-fiber";
import TextCanvas from "text-canvas";
import {
  deleteText,
  setHovered,
  setSelectedId,
  setTextEditor,
  setClassParam
} from "../../../../../../slices/threed-canvas-screenshot";
import { usePrevious } from "../../../../../Common/Functions";

if (typeof window !== "undefined") {
  var WebFont = require("webfontloader");
}

/** This renders text via canvas and projects it as a sprite */
export default ({ data }) => {
  let dispatch = useDispatch();

  let checkpoints = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.checkpoints
  );
  let playback = useSelector((state) => state.ScreenshotsCanvasThreeD.present.playback);
  let recording = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.recording.ready
  );

  let object = React.useRef();
  let spriteRef = React.useRef();
  let time = React.useRef(TWEEN.now())
  
  const [canvas, setCanvas] = React.useState(null);
  const [positionAnimations, setPositionAnimations] = React.useState(null);
  const [visibleAnimations, setVisibleAnimations] = React.useState(null);
  const [tweenGroup, setTweenGroup] = React.useState(new TWEEN.Group());

    let id = data.id.replace(/\D/g, "")

  useFrame((state, delta) => {
    if (delta && playback && !recording) {
      time.current += delta * 1000;
      tweenGroup.update(time.current);
    } else if (recording && state.invalidateFrameloop && state.frames === 1) {
      tweenGroup.update(time.current);
      time.current += 1000 / 30;
    }
  }, 1);

  let oldFontFamily = usePrevious(data.fontFamily);
  let oldFontWeight = usePrevious(data.fontWeight);

  function onHover(e) {
    e.stopPropagation();
    dispatch(setHovered({ object: object.current, group: true }));
  }
  function onHoverOff(e) {
    e.stopPropagation();
    dispatch(setHovered({ object: null, group: false }));
  }

  function onClick(e) {
    e.stopPropagation();
    dispatch(setSelectedId(data.id));
  }

  React.useEffect(() => {
    if (object.current) {
      let newCheckpoints = checkpoints.durations.slice(checkpoints.current);
      let positions = data.positions.slice(checkpoints.current);
      let visibles = data.visible.slice(checkpoints.current);
      let transitions = checkpoints.transitions.slice(checkpoints.current);

      let positionAnimations = [];
      tweenGroup.removeAll();
      setPositionAnimations(null);
      setVisibleAnimations(null);
      if (newCheckpoints.length > 1) {
        for (let i = 0; i < newCheckpoints.length - 1; i++) {
          let transition = transitions[i].split(".");
          let positionStart = { ...positions[i] };
          let positionEnd = { ...positions[i + 1] };

          let positionTween = new TWEEN.Tween(positionStart, tweenGroup)
            .to(positionEnd, newCheckpoints[i + 1] * 1000)
            .easing(TWEEN.Easing[transition[0]][transition[1]])
            .onUpdate(() => {
              object.current.position.set(
                positionStart.x,
                positionStart.y,
                positionStart.z
              );
            }).onComplete(() => {
              if(i === newCheckpoints.length - 2){
                object.current.position.set(positions[0].x, positions[0].y, positions[0].z)
                if(!visibles[0]){
                  object.current.visible = false
                  object.current.children[0].material.opacity = 0
                }
              }
            })

          if (!visibles[i] && visibles[i + 1]) {
            let delay =
              newCheckpoints.slice(1, i + 2).reduce((a, b) => a + b, 0) * 1000;

            let opacity = {
              value: 0,
            };
            var visibleTween = new TWEEN.Tween(opacity, tweenGroup)
              .to({ value: 1 }, 2000)
              .onUpdate(() => {
                object.current.children[0].material.opacity = opacity.value;
              })
              .onStart(() => {
                object.current.visible = true;
              })
              .delay(delay);
          }

          if (positionAnimations.length > 0) {
            positionAnimations[i - 1].chain(positionTween);
          }

          positionAnimations.push(positionTween);
        }
        setVisibleAnimations(visibleTween);
        setPositionAnimations(positionAnimations);
      }
    }
  }, [object.current, data, checkpoints]);

  React.useEffect(() => {
    if (object.current && !playback && !recording) {
      if (positionAnimations && positionAnimations[0].isPlaying)
        positionAnimations[0].stop();
      if (visibleAnimations && visibleAnimations.isPlaying)
        visibleAnimations.stop();
      object.current.position.set(
        data.positions[checkpoints.current].x,
        data.positions[checkpoints.current].y,
        data.positions[checkpoints.current].z
      );
      object.current.visible = data.visible[checkpoints.current];
      object.current.children[0].material.opacity = data.visible[
        checkpoints.current
      ]
        ? 1
        : 0;
    } else if (playback || recording) {
      time.current = TWEEN.now();
      if (positionAnimations) positionAnimations[0].start();
      if (visibleAnimations) visibleAnimations.start();
    }
  }, [
    playback,
    recording,
    checkpoints,
    positionAnimations,
    visibleAnimations,
    object.current,
  ]);

  React.useEffect(() => {
    function createText() {
      const textCanvas = new TextCanvas(
        data.text,
        {
          fontFamily: data.fontFamily,
          fontSize: data.fontSize,
          textColor: data.fill,
          fontWeight: data.fontWeight,
          lineHeight:
            data.lineHeight > data.fontSize * 1.2
              ? data.lineHeight
              : data.fontSize * 1.2,
          textAlign: data.textAlign,
          fontStyle: data.italic ? "italic" : "normal",
        },
        data.scale > 50 ? 20 : 10
      );

      let newCanvas = textCanvas.render();
      let ratio = newCanvas.height / newCanvas.width;
      setCanvas(
        <group
          ref={object}
          visible={data.visible[checkpoints.current]}
          scale={[data.scale, data.scale * ratio, 1]}
          position={[
            data.positions[checkpoints.current].x,
            data.positions[checkpoints.current].y,
            data.positions[checkpoints.current].z,
          ]}
          userData={{
            setPosition: (position) => {
              dispatch(
                setClassParam({
                  class: 'texts',
                  id,
                  position,
                })
              );
            },
          }}
        >
          <sprite
            name={"sprite"}
            ref={spriteRef}
            onClick={data.visible[checkpoints.current] ? onClick : null}
            onPointerOver={data.visible[checkpoints.current] ? onHover : null}
            onDoubleClick={
              data.visible[checkpoints.current]
                ? () => dispatch(setTextEditor(true))
                : null
            }
            frustumCulled={false}
          >
            <spriteMaterial attach="material" transparent>
              <canvasTexture
                attach="map"
                image={newCanvas}
                premultiplyAlpha
                onUpdate={(s) => (s.needsUpdate = true)}
              />
            </spriteMaterial>
          </sprite>
        </group>
      );
    }

    function loadFontFirst(callback) {
      if (typeof window !== "undefined") {
        WebFont.load({
          google: {
            families: [data.fontFamily + ":" + data.fontWeight],
          },
          timeout: 5000,
          classes: false,
          fontactive: function (familyName, fvd) {
            callback();
          },
        });
      }
    }
    if(!data.text || data.text.length === 0){
      dispatch(deleteText(id))
    }
    else if (
      oldFontFamily !== data.fontFamily ||
      oldFontWeight !== data.fontWeight
    ) {
      loadFontFirst(createText);
    } else {
      createText();
    }
  }, [
    data,
    oldFontWeight,
    oldFontFamily,
    checkpoints.current,
    playback,
    recording,
  ]);

  return canvas;
};
