import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDimensions, setFloorParam
} from "../../../../../../slices/threed-canvas-screenshot";
import GroupedSetting from "../../../../../Common/GroupedSetting/GroupedSetting";
import TrueFalse from "../../../../../Common/TrueFalse/TrueFalse";
import Slider from '../../../../../Common/Slider/Slider';

import * as styles from "./advancedtab.module.scss";

export default () => {
  let data = useSelector(state => state.ScreenshotsCanvasThreeD.present);
  let dispatch = useDispatch();

  return (
    <div className={styles.advancedTabContainer}>
      <h3>Dimensions</h3>
      <div className={styles.dimensionsDiv}>
        <div>
          <span>Width:</span>
          <input
                    className={styles.dimensions}
            type="number"
            value={data.dimensions.width}
            onChange={(e) =>
              e.target.value <= 5000 &&
              e.target.value >= 0 &&
              Number.isInteger(parseInt(e.target.value))
                ? dispatch(
                    setDimensions({
                      ...data.dimensions,
                      width: parseInt(e.target.value),
                    })
                  )
                : null
            }
          />
        </div>
        <span>X</span>
        <div>
          <span>Height:</span>
          <input
          className={styles.dimensions}
            type="number"
            value={data.dimensions.height}
            onChange={(e) =>
              e.target.value <= 5000 &&
              e.target.value >= 0 &&
              Number.isInteger(parseInt(e.target.value))
                ? dispatch(
                    setDimensions({
                      ...data.dimensions,
                      height: parseInt(e.target.value),
                    })
                  )
                : null
            }
          />
        </div>
      </div>
      {/* <GroupedSetting title={"Floor"}>
      <TrueFalse active={data.floor.active} onClick={(value) => dispatch(setFloorParam({param: 'active', value}))}/>
      <Slider min={-100} name={'Height'} max={100} step={0.01} value={data.floor.y} onChange={(value) => dispatch(setFloorParam({param:'y', value}))}/>
          <Slider name={'Opacity'} min={0} max={1} step={0.001} value={data.floor.opacity} onChange={(value) => dispatch(setFloorParam({param:'opacity', value}))}/>
          <Slider name={'Blur'} min={0} max={5} step={0.01} value={data.floor.blur} onChange={(value) => dispatch(setFloorParam({param:'blur', value}))}/>
      </GroupedSetting> */}
    </div>
  );
};
