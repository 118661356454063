import React from 'react'
import { useThree } from 'react-three-fiber'
import * as THREE from 'three'

function getCoordinates(radian, width, height) {
    radian += Math.PI;
    let halfWidth = width * 0.5;
    let halfHeight = height * 0.5;
    let lineLength = Math.abs(width * Math.sin(radian)) + Math.abs(height * Math.cos(radian));
    let halfLineLength = lineLength / 2;

    let x0 = halfWidth + Math.sin(radian) * halfLineLength;
    let y0 = halfHeight - Math.cos(radian) * halfLineLength;
    let x1 = width - x0;
    let y1 = height - y0;

    return {x0, x1, y0, y1};
}

export default ({background, dimensions}) => {
    const { scene, gl } = useThree()
    React.useMemo(() => {
        if(background.priority === 'solid'){
            // let alpha = background.solid.replace(/^.*,(.+)\)/,'$1')

            // var finalBackground = new THREE.Color(background.solid);
            // gl.setClearColor(finalBackground, alpha > 1 ? alpha/100 : alpha)
            // scene.background = null
            var finalBackground = new THREE.Color(background.solid);
        }
        else if(background.priority === 'pattern'){
            var finalBackground = new THREE.TextureLoader().load(background.image)
            finalBackground.encoding = THREE.sRGBEncoding
            // scene.background = finalBackground
        }
        else {
            let canvas = document.createElement('canvas')
            canvas.width = dimensions.width
            canvas.height = dimensions.height
            let ctx = canvas.getContext("2d")
            let cssAng = background.linear.deg * Math.PI / 180
            var dir = getCoordinates(cssAng, dimensions.width, dimensions.height);
            var gr = ctx.createLinearGradient(dir.x0,dir.y0,dir.x1,dir.y1);
            gr.addColorStop(background.linear.percent1/100, background.linear.color1);
            gr.addColorStop(background.linear.percent2/100, background.linear.color2);
            ctx.fillStyle = gr;
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            let url = canvas.toDataURL()
            var finalBackground = new THREE.TextureLoader().load(url)
            finalBackground.encoding = THREE.sRGBEncoding
            // scene.background = finalBackground
        }
        scene.background = finalBackground

    }, [background, dimensions])
    return null
}