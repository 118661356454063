import FontPicker from "font-picker-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    removeClass,
    setClassParam
} from "../../../../../../slices/threed-canvas-screenshot";
import ColorPicker from "../../../../../Common/ColorPicker/ColorPicker";
import Alignment from "../../../../../Common/Alignment/Alignment";
import DeleteButton from "../../../../../Common/DeleteButton/DeleteButton";
import Select from "../../../../../Common/Select/Select";
import TrueFalse from "../../../../../Common/TrueFalse/TrueFalse";
import Slider from "../../../../../Common/Slider/Slider";
import * as styles from "./texttab.module.scss";

export default ({id}) => {
  let dispatch = useDispatch()
  let [fontWeights, setFontWeights] = React.useState([])
  let currentText = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.texts[id]
  );

  React.useEffect(() => {
      async function getFontOnPageLoad(){
          if(id){
              let newWeights = await fetchNewWeights(currentText.fontFamily)
              setFontWeights(newWeights)
          }
      }
      getFontOnPageLoad()
  }, [currentText])

  async function fetchNewWeights(family){
      let response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU')
      let responseJson = await response.json() 
      for(let i=0; i<responseJson.items.length; i++){
          if(family === responseJson.items[i].family){
              let newWeights = getWeights(responseJson.items[i].variants)
              return newWeights
          } 
      }
      return []
  }

  function getWeights(variants){
      let newWeights = variants.reduce((list, variant) => {
          if(/^\d+$/.test(variant)){
              list.push(variant)
          }
          return list
      }, [])
      if(newWeights.length === 0) {
          newWeights = ['400']
      }
      return newWeights
  }
  
  async function changeText(param, value){
      if(param === 'fontFamily'){
              value = value.family
              let newWeights = await fetchNewWeights(value)

              if(!newWeights.includes(currentText.fontWeight)){
                dispatch(setClassParam({class: 'texts', id, param: 'fontWeight', value: newWeights[0]})) // If current weight isn't available in the new family, change it to first available weight
              }
              setFontWeights(newWeights)
      }
      dispatch(setClassParam({class: 'texts', id, param, value}))
  }

  function removeText(){
      dispatch(removeClass({class: 'texts', id}))
  }

  return (
    <div className={styles.container}>
      <h3>Color</h3>
      <ColorPicker onChange={(color) => changeText('fill', color)} startColor={currentText.fill}/>
      <h3>Font</h3>
      <FontPicker
              className={styles.fontPicker}
              apiKey="AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU"
              activeFontFamily={currentText.fontFamily}
              onChange={(nextFont) => nextFont.family !== currentText.fontFamily ? changeText('fontFamily', nextFont) : null}
              key={id}
          />
      <h3>Size</h3>
      <Slider value={currentText.scale} onChange={(value) => changeText('scale', value)} />
      <h3>Line Height</h3>
      <Slider min={currentText.fontSize * 1.2} max={currentText.fontSize + 100} step={0.001} value={currentText.lineHeight > currentText.fontSize * 1.2 ? currentText.lineHeight : currentText.fontSize * 1.2} onChange={(value) => changeText('lineHeight', parseFloat(value)) }/>
      <h3>Weight</h3>
      <Select options={fontWeights} value={currentText.fontWeight} onChange={value => changeText('fontWeight', value)} />
      <h3>Alignment</h3>
      <Alignment active={currentText.textAlign} onClick={(option) => changeText('textAlign', option)}/>
      <h3>Italic</h3>
      <TrueFalse active={currentText.italic} onClick={(option) => changeText('italic', option)} />
      <DeleteButton onClick={removeText} style={{marginTop: 10}} />
    </div>
  );
};
