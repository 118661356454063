import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { extend, useFrame, useThree } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { setCameraParams, setDragging } from '../../../../../../slices/threed-canvas-screenshot'

extend({ OrbitControls })

export default (props) => {
    const ref = React.useRef()
    let dispatch = useDispatch()
    let checkpoints = useSelector(state => state.ScreenshotsCanvasThreeD.present.checkpoints)
    let control = useSelector(state => state.ScreenshotsCanvasThreeD.present.control)
    let playback = useSelector(state => state.ScreenshotsCanvasThreeD.present.playback)

    const { camera, gl } = useThree()
    useFrame(() => ref.current && ref.current.update())


    function onStart(){
      dispatch(setDragging(true))
    }
    function onEnd(e){
      dispatch(setDragging(false))
      dispatch(setCameraParams({
        id: checkpoints.current, 
        position: {...e.target.object.position},
        zoom: e.target.object.zoom
      }))
    }

    React.useEffect(() => {
      if(ref.current){
        ref.current.addEventListener('start', onStart, true)
        ref.current.addEventListener('end', onEnd, true)
        return () => {
          if(ref.current){
            ref.current.removeEventListener('start', onStart)
            ref.current.removeEventListener('end', onEnd)
          }
        }
      }
    }, [ref.current, checkpoints.current])


    return <orbitControls minPolarAngle={Math.PI*0.05} maxPolarAngle={Math.PI*0.95} ref={ref} args={[camera, gl.domElement]} {...props} enabled={control === 'camera' && !playback ? true : false} />
  }