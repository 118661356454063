import React from "react";
import * as styles from "./texteditor.module.scss";

export default ({ currentText, onSave, onCancel }) => {
  const [text, setText] = React.useState(currentText.text);

  const onSaveClick = () => {
    let newText = text.toString().replace(/^\s+|\s+$/g, "");
    onSave({ ...currentText, text: newText });
  };
  const onCancelClick = () => {
    onCancel();
  };

  return (
    <div className={styles.textEditorContainer}>
      <div className={styles.inner}>
        <div
          contentEditable
          className={styles.text}
          onInput={(e) => setText(e.target.innerText)}
        >
          {currentText.text}
        </div>
        <div className={styles.buttons}>
          <button className={styles.cancel} onClick={onCancelClick}>
            Cancel
          </button>
          <button className={styles.save} onClick={onSaveClick}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
