import AddIcon from '../../../../../assets/svg/add.svg'
import PlayArrowIcon from '../../../../../assets/svg/3d-maker/play.svg'
import PauseIcon from '../../../../../assets/svg/3d-maker/pause.svg'
import cloneDeep from 'clone-deep'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../../../../../configureStore'
import DeleteSvg from '../../../../../assets/svg/bin.svg'
import { addCheckpoint, deleteCheckpoint, setCheckpointDuration, setCheckpoints, setCheckpointTransition, setClicked, setPlayback } from '../../../../../slices/threed-canvas-screenshot'
import Transition from './Transition/Transition'
import * as styles from './timeframetab.module.scss'


export default () => {
    let dispatch = useDispatch()
    let checkpoints = useSelector(state => state.ScreenshotsCanvasThreeD.present.checkpoints)
    let playback = useSelector(state => state.ScreenshotsCanvasThreeD.present.playback)

    let totalDuration = checkpoints.durations.reduce((a,b) => a + b, 0)

    function addNewCheckpoint(){
        let data = cloneDeep(store.getState().ScreenshotsCanvasThreeD.present)

        let texts = [...data.texts]
        let images = [...data.images]
        let devices = [...data.devices]

        texts.forEach(item => {
            if(Object.keys(item).length !== 0){
                item.positions = [...item.positions, {...item.positions[item.positions.length - 1]}]
                item.visible = [...item.visible, item.visible[item.visible.length - 1]]
            }
        });

        devices.forEach(item => {
            if(Object.keys(item).length !== 0){
                item.positions = [...item.positions, {...item.positions[item.positions.length - 1]}]
                item.rotations = [...item.rotations, {...item.rotations[item.rotations.length - 1]}]
            }
        });

        images.forEach(item => {
            if(Object.keys(item).length !== 0){
                item.positions = [...item.positions, {...item.positions[item.positions.length - 1]}]
                item.visible = [...item.visible, item.visible[item.visible.length - 1]]
            }
        });
        

        // Add checkpoint
        dispatch(addCheckpoint({duration: 5, texts, images, devices}))
    }

    const VisualCheckpoints = () => checkpoints.durations.map((duration, index) => {
            return(
                <div key={index}>
                {index !== 0 ?
                <div className={styles.checkpointDuration}>
                    <span><input onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault()
                            e.currentTarget.blur()
                            return false;
                        }
                    }} onBlur={e => {
                        e.target.value > 0 ? dispatch(setCheckpointDuration({id: index, duration: parseFloat(e.target.value)})) : e.target.value = duration
                    }} defaultValue={duration}/>s</span>
                    <Transition onChange={(transition) => dispatch(setCheckpointTransition({index: index - 1, transition}))} current={checkpoints.transitions[index - 1]}/>
                    {/* <TimeSvg /> */}
                </div>
                :null}
                <div 
                onClick={() => {
                    dispatch(setClicked({object: null, group: false}))
                    dispatch(setCheckpoints({...checkpoints, current: index}))
                }}
                className={`${styles.checkpoint} ${index === checkpoints.current ? styles.checkpointActive : null}`}>
                    {index + 1}
                    {index ? 
                    <div className={styles.deleteCheckpoint} onClick={(e) => {
                            e.stopPropagation()
                            dispatch(deleteCheckpoint(index))
                        }}>
                        <DeleteSvg />
                    </div>  : null}
                </div>
                </div>
            )
        })
    function onRecordClick(e){
        let playbackDiv = e.currentTarget
        let canvas = store.getState().ScreenshotsCanvasThreeD.present.renderer.domElement

        if(!playback && checkpoints.current !== checkpoints.durations.length - 1){
            e.stopPropagation()
            document.body.addEventListener('click', (e) => {
                canvas.style.pointerEvents = 'auto'
                dispatch(setPlayback(false))
                if(e.currentTarget == playbackDiv){
                    e.stopPropagation();
                }
            }, {once: true} )
            dispatch(setPlayback(true))
            canvas.style.pointerEvents = 'none'
        }
    }

    return(
        checkpoints.durations.length === 1 ? <div className={styles.createAnimationContainer}>
            <button onClick={addNewCheckpoint}>Create Animation</button>
        </div> :
        <div className={styles.container}>
            <div className={styles.topLevel}>
             <div className={styles.time}>
                 {totalDuration}s Animation
             </div>
             <div className={styles.playback} onClick={onRecordClick}>
                 {playback ? 
                 <PauseIcon /> : <PlayArrowIcon />}
             </div>
            </div>
            <div className={styles.lowLevel}>
                <div className={styles.checkpointsDiv}>
                    <VisualCheckpoints />
                </div>
                <div className={styles.addCheckpoint} onClick={addNewCheckpoint}>
                    <AddIcon />
                </div>
            </div>
        </div>
    )
}