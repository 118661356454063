import React from 'react'
import BackgroundPicker from '../../../../../Common/BackgroundPicker/BackgroundPicker'
import { useSelector, useDispatch } from 'react-redux'
import { setBackground } from '../../../../../../slices/threed-canvas-screenshot';

export default () => {
    let dispatch = useDispatch()
    let background = useSelector(state => state.ScreenshotsCanvasThreeD.present.background)
    let dimensions = useSelector(state => state.ScreenshotsCanvasThreeD.present.dimensions)

    return(
        <BackgroundPicker background={background} dimensions={dimensions} setBackground={(value) => dispatch(setBackground(value))}/>
    )
}