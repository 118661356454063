import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {     removeClass,
  setClassParam } from '../../../../../../slices/threed-canvas-screenshot';
import CollectionComponent from '../../../../../Common/CollectionComponent/CollectionComponent';
import DeleteButton from '../../../../../Common/DeleteButton/DeleteButton';
import Slider from '../../../../../Common/Slider/Slider';
import UploadMedia from '../../../../../Common/UploadMedia/UploadMedia';
import * as styles from './imagetab.module.scss';



export default ({id}) => {
  let dispatch = useDispatch()
  let currentImage = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.images[id]
  );

function changeImage(param, value){
  dispatch(setClassParam({class: 'images', id, param, value}))
}
function removeImage(){
  dispatch(removeClass({class: 'images', id}))
}

  return (
    <div>
      <h3>Image</h3>
      <UploadMedia onUpload={(src) => changeImage('img', src)} label={'Upload Image'}/>
        <h3>Collections</h3>
        <CollectionComponent requiredCollections={['Store Badges']} onSelect={(src) => changeImage('img', src)} canvasProportion={0} style={{backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'}} />
        <h3>Size</h3>
        <Slider min={0} max={30} step={0.01} value={currentImage.scale * 10} onChange={(value) => changeImage('scale', value/10)}/>
        <DeleteButton onClick={removeImage} style={{marginTop: 10}} />
    </div>
  )
  }