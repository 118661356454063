import { ResizeObserver } from "@juggle/resize-observer";
import React, { Suspense } from "react";
import {
  Provider,
  ReactReduxContext,
  useDispatch,
  useSelector
} from "react-redux";
import { Canvas } from "react-three-fiber";
import * as THREE from "three";
import {
  setCameraZooms,
  setRenderer,
  setText,
  setTextEditor,
  setBackgroundImage
} from "../../../../../slices/threed-canvas-screenshot";
import { getNewImage, updateCanvasDimensions, usePrevious } from "../../../../Common/Functions";
import Background from "./CanvasComponents/Background";
import Camera from "./CanvasComponents/Camera";
import Device from "./CanvasComponents/Device";
import DragAndDrop from "./CanvasComponents/DragAndDrop";
import Image from "./CanvasComponents/Image";
import Lights from "./CanvasComponents/Lights";
import OrbitControl from "./CanvasComponents/OrbitControl";
import Plane from "./CanvasComponents/Plane";
import Text from "./CanvasComponents/Text";
import VideoCapture from "./CanvasComponents/VideoCapture";
import TextEditor from "./TextEditor/TextEditor";

export default () => {
  let dispatch = useDispatch();
  let outputDimensions = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.dimensions
  );
  let hovered = useSelector((state) => state.ScreenshotsCanvasThreeD.present.hovered);
  let selectedId = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.selectedId
  );
  let dataTexts = useSelector((state) => state.ScreenshotsCanvasThreeD.present.texts);
  let dataDevices = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.devices
  );
  let dataImages = useSelector((state) => state.ScreenshotsCanvasThreeD.present.images);
  let recordingScale = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.recording.scale
  );
  let background = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.background
  );
  let textEditor = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.textEditor
  );


  const [canvasDimensions, setCanvasDimensions] = React.useState({});
  const [texts, setTexts] = React.useState(null);
  const [images, setImages] = React.useState(null);
  const [devices, setDevices] = React.useState(null);
  const [renderer, setRendererState] = React.useState(null);
  const [camera, setCamera] = React.useState(null);
  const [raycaster, setRaycaster] = React.useState(null);

  let oldDimensions = usePrevious(outputDimensions);

  // function onCanvasClick() {
  //   if (raycaster.intersectObjects(scene.children, true).length === 0) {
  //     dispatch(setSelectedId("background"));
  //   }
  // }

  React.useEffect(() => {
    let newTexts = () =>
      dataTexts.map((item, index) => {
        if (Object.keys(dataTexts[index]).length !== 0) {
          return <Text data={item} key={item.id} />;
        }
      });
    setTexts(newTexts());
  }, [dataTexts]);

  React.useEffect(() => {
    let newDevices = () =>
      dataDevices.map((item, index) => {
        if (Object.keys(dataDevices[index]).length !== 0) {
          return (
            <Suspense fallback={null} key={item.id}>
              <Device currentDevice={item} key={item.id} />
            </Suspense>
          );
        }
      });
    setDevices(newDevices());
  }, [dataDevices]);

  React.useEffect(() => {
    let newImages = () =>
      dataImages.map((item, index) => {
        if (Object.keys(dataImages[index]).length !== 0) {
          return <Image data={item} key={item.id} />;
        }
      });
    setImages(newImages());
  }, [dataImages]);

  React.useEffect(() => {
    async function fetchDimensions() {
      if(!recordingScale){
        let dimensions = await updateCanvasDimensions(outputDimensions);
        if (camera && renderer) {
          renderer.setSize(dimensions.width, dimensions.height);
  
          camera.aspect = dimensions.width / dimensions.height;
  
          if (
            oldDimensions.width !== outputDimensions.width ||
            oldDimensions.height !== outputDimensions.height
          ) {
            dispatch(setCameraZooms(dimensions.width / outputDimensions.width));
            // camera.zoom = dimensions.width / outputDimensions.width
          }
          camera.updateProjectionMatrix();
        }
  
        if (
          dimensions.width !== canvasDimensions.width ||
          dimensions.height !== canvasDimensions.height
        ) {
          setCanvasDimensions(dimensions);
        }
      }
    }

    fetchDimensions();
    let parent = document.getElementById("stage-parent");
    const resizeObserver = new ResizeObserver(fetchDimensions);

    resizeObserver.observe(parent);

    return () => {
      resizeObserver.disconnect();
    };
  }, [outputDimensions, renderer, camera, canvasDimensions, oldDimensions, recordingScale]);

  React.useEffect(() => { // Delete after
    async function checkImage(){
      if(background.image && background.image.includes('static')){
        let newUrl = await getNewImage(background.image)
        dispatch(setBackgroundImage(newUrl))
      }
    }
    checkImage()
  }, [background.image])

  return (
    <>
      {textEditor && selectedId.includes('text') ? (
        <TextEditor
          currentText={dataTexts[selectedId.replace(/\D/g, "")]}
          onCancel={() => dispatch(setTextEditor(false))}
          onSave={(text) => {
            dispatch(setText({ id: selectedId.replace(/\D/g, ""), text }));
            dispatch(setTextEditor(false));
          }}
        />
      ) : null}
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <Canvas
            concurrent 
            resize={{ polyfill: ResizeObserver }}
            colorManagement={true}
            invalidateFrameloop={recordingScale ? true : false}
            // onClickCapture={onCanvasClick}
            style={{
              height: canvasDimensions.height,
              width: canvasDimensions.width,
            }}
            gl={{
              preserveDrawingBuffer: true,
            }}
            onCreated={({ gl, raycaster, scene }) => {
              setRendererState(gl);
              setRaycaster(raycaster);
              dispatch(setRenderer(gl));

              gl.toneMapping = THREE.NoToneMapping;
              gl.physicallyCorrectLights = true;
              gl.outputEncoding = THREE.sRGBEncoding;
            }}
          >
            <Provider store={store}>
              <OrbitControl enablePan={false} enableRotate={true} />
              <Camera onCreated={(camera) => setCamera(camera)} />
              <VideoCapture />
              <Background
                background={background}
                dimensions={outputDimensions}
              />
              <DragAndDrop hovered={hovered} />
              <Lights />
              {/* <Plane /> */}
              {texts}
              {images}
              {devices}
            </Provider>
          </Canvas>
        )}
      </ReactReduxContext.Consumer>
    </>
  );
};
