import React from 'react'
import * as styles from './transition.module.scss'
import CloseIcon from '../../../../../../assets/svg/3d-maker/close.svg';
import LinearNoneSvg from '../../../../../../assets/svg/transitions/linear-none.svg'
import CircularInSvg from '../../../../../../assets/svg/transitions/circular-in.svg'
import CircularInOutSvg from '../../../../../../assets/svg/transitions/circular-in-out.svg'
import CircularOutSvg from '../../../../../../assets/svg/transitions/circular-out.svg'
import CubicInSvg from '../../../../../../assets/svg/transitions/cubic-in.svg'
import CubicInOutSvg from '../../../../../../assets/svg/transitions/cubic-in-out.svg'
import CubicOutSvg from '../../../../../../assets/svg/transitions/cubic-out.svg'
import ElasticInSvg from '../../../../../../assets/svg/transitions/elastic-in.svg'
import ElasticInOutSvg from '../../../../../../assets/svg/transitions/elastic-in-out.svg'
import ElasticOutSvg from '../../../../../../assets/svg/transitions/elastic-out.svg'
import QuadraticInSvg from '../../../../../../assets/svg/transitions/quadratic-in.svg'
import QuadraticInOutSvg from '../../../../../../assets/svg/transitions/quadratic-in-out.svg'
import QuadraticOutSvg from '../../../../../../assets/svg/transitions/quadratic-out.svg'

export default ({onChange, current}) => {
    const [open, setOpen] = React.useState(false)
    
    let options = [
        {
            name: 'Linear',
            icon: <LinearNoneSvg />,
            function: 'Linear.None',
        },
        {
            name: 'Quadratic In',
            icon: <QuadraticInSvg />,
            function: 'Quadratic.In',
        },
        {
            name: 'Quadratic Out',
            icon: <QuadraticOutSvg />,
            function: 'Quadratic.Out',
        },
        {
            name: 'Quadratic In & Out',
            icon: <QuadraticInOutSvg />,
            function: 'Quadratic.InOut',
        },
        {
            name: 'Circular In',
            icon: <CircularInSvg />,
            function: 'Circular.In',
        },
        {
            name: 'Circular Out',
            icon: <CircularOutSvg />,
            function: 'Circular.Out',
        },
        {
            name: 'Circular In & Out',
            icon: <CircularInOutSvg />,
            function: 'Circular.InOut',
        },

        {
            name: 'Cubic In',
            icon: <CubicInSvg />,
            function: 'Cubic.In',
        },
        {
            name: 'Cubic Out',
            icon: <CubicOutSvg />,
            function: 'Cubic.Out',
        },
        {
            name: 'Cubic In & Out',
            icon: <CubicInOutSvg />,
            function: 'Cubic.InOut',
        },

        {
            name: 'Elastic In',
            icon: <ElasticInSvg />,
            function: 'Elastic.In',
        },
        {
            name: 'Elastic Out',
            icon: <ElasticOutSvg />,
            function: 'Elastic.Out',
        },
        {
            name: 'Elastic In & Out',
            icon: <ElasticInOutSvg />,
            function: 'Elastic.InOut',
        },
    ]

    const OptionsContainer = () => {
        const Options = () => options.map((option, index) => {
            return(
                <li key={index} onClick={() => {
                    setOpen(false)
                    onChange(option.function)
                }}>
                    {option.icon}
                    {option.name}
                    </li>
            )
        })
        return(
            <div className={styles.optionsContainer}>
                <div className={styles.header}>
                    <span>Transition</span>
                    <CloseIcon onClick={() => setOpen(false)}/>
                </div>
                <ul>
                    <Options />
                </ul>
                <span className={styles.arrow}/>
            </div>
        )
    }

    const CurrentIcon = React.useMemo(() => {
        let option = options.filter(x => x.function == current)
        return option.length ? option[0].icon : null
    }, [current])

    return(
        <div className={styles.container}>
            <div className={styles.icon} onClick={() => setOpen(!open)}>
                {CurrentIcon}
            </div>
            {open ?
            <OptionsContainer /> : null}
        </div>
    )
}