import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeClass,
  setClassParam,
} from "../../../../../../slices/threed-canvas-screenshot";
import ColorPicker from "../../../../../Common/ColorPicker/ColorPicker";
import DeleteButton from "../../../../../Common/DeleteButton/DeleteButton";
import GroupedSetting from "../../../../../Common/GroupedSetting/GroupedSetting";
import Select from "../../../../../Common/Select/Select";
import Slider from "../../../../../Common/Slider/Slider";
import UploadMedia from "../../../../../Common/UploadMedia/UploadMedia";
import * as styles from "./devicetab.module.scss";

export default ({ id }) => {
  let dispatch = useDispatch();
  let currentDevice = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.devices[id]
  );
  let checkpoint = useSelector(
    (state) => state.ScreenshotsCanvasThreeD.present.checkpoints.current
  );

  function changeParam(param, value, axis) {
    if (param === "rotations") {
      value = degrees_to_radians(value);
    }
    dispatch(setClassParam({ class: "devices", id, param, value, axis }));
  }

  function removeDevice() {
    dispatch(removeClass({ class: "devices", id }));
  }

  function degrees_to_radians(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }

  function radians_to_degrees(radians) {
    var pi = Math.PI;
    return radians / (pi / 180);
  }

  return (
    <div className={styles.framesContainer}>
      <h3>Color</h3>
      <ColorPicker
        startColor={currentDevice.color}
        onChange={(color) => changeParam("color", color)}
      />
      <h3>Screenshot</h3>
      <UploadMedia
        video
        onUpload={(url) => changeParam("screenshot", url)}
        label={"Upload Media"}
      />
      <h3>Device</h3>
      <Select
        options={["iPhone 11", "iPhone 12", "iPad", "MacBook"]}
        value={currentDevice.frame}
        onChange={(frame) => changeParam("frame", frame)}
      />
      <h3>Size</h3>
      <Slider
        min={0}
        max={20}
        step={0.01}
        value={currentDevice.scale * 100}
        onChange={(value) => changeParam("scale", value / 100)}
      />
      <GroupedSetting title={"Position"}>
        <Slider
          min={-100}
          max={100}
          step={0.001}
          name={"X"}
          value={currentDevice.positions[checkpoint].x}
          onChange={(value) => changeParam("positions", parseFloat(value), "x")}
        />
        <Slider
          min={-100}
          max={100}
          step={0.001}
          name={"Y"}
          value={currentDevice.positions[checkpoint].y}
          onChange={(value) => changeParam("positions", parseFloat(value), "y")}
        />
        <Slider
          min={-100}
          max={100}
          step={0.001}
          name={"Z"}
          value={currentDevice.positions[checkpoint].z}
          onChange={(value) => changeParam("positions", parseFloat(value), "z")}
        />
      </GroupedSetting>
      <GroupedSetting title={"Rotation"}>
        <Slider
          min={-500}
          max={500}
          step={0.001}
          name={"X"}
          value={radians_to_degrees(currentDevice.rotations[checkpoint].x)}
          onChange={(value) => changeParam("rotations", parseFloat(value), "x")}
        />
        <Slider
          min={-500}
          max={500}
          step={0.001}
          name={"Y"}
          value={radians_to_degrees(currentDevice.rotations[checkpoint].y)}
          onChange={(value) => changeParam("rotations", parseFloat(value), "y")}
        />
        <Slider
          min={-500}
          max={500}
          step={0.001}
          name={"Z"}
          value={radians_to_degrees(currentDevice.rotations[checkpoint].z)}
          onChange={(value) => changeParam("rotations", parseFloat(value), "z")}
        />
      </GroupedSetting>
      <DeleteButton onClick={removeDevice} style={{ marginTop: 10 }} />
    </div>
  );
};
