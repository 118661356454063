import TWEEN from '@tweenjs/tween.js'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFrame } from 'react-three-fiber'
import * as THREE from 'three'
import { setHovered, setImage, setSelectedId, setClassParam } from '../../../../../../slices/threed-canvas-screenshot'
import { getNewImage } from '../../../../../Common/Functions'

export default ({data}) => {
    const [displayedImage, setDisplayedImage] = React.useState(null)
    const [positionAnimations, setPositionAnimations] = React.useState(null)
    const [visibleAnimations, setVisibleAnimations] = React.useState(null)
    const [tweenGroup, setTweenGroup] = React.useState(new TWEEN.Group())
    
    let dispatch = useDispatch()
    let object = React.useRef()
    let spriteRef = React.useRef()
    let time = React.useRef(TWEEN.now())

    let checkpoints = useSelector(state => state.ScreenshotsCanvasThreeD.present.checkpoints)
    let playback = useSelector(state => state.ScreenshotsCanvasThreeD.present.playback)
    let recording = useSelector(state => state.ScreenshotsCanvasThreeD.present.recording.ready)

    var id = data.id.replace(/\D/g,'')

    useFrame((state, delta) => {
      if(delta && playback && !recording){
        time.current += delta * 1000
        tweenGroup.update(time.current)
      }
      else if(recording && state.invalidateFrameloop  && state.frames === 1){
        tweenGroup.update(time.current)
        time.current += 1000/30
      }
    }, 1)

    React.useEffect(() => { // Delete after
      async function checkImage(){
        if(data.img && data.img.includes('static')){
          let newUrl = await getNewImage(data.img)
          dispatch(setClassParam({
            class: 'images',
            id,
            param: 'img',
            value: newUrl
          }))
        }
      }
      checkImage()
    }, [data.img])

    const texture = React.useMemo(() => new THREE.TextureLoader().load(data.img), [data.img])

    function onHover(e){
        e.stopPropagation()
        dispatch(setHovered({object: object.current, group: true}))
      }
    function onHoverOff(e){
        // e.stopPropagation()
        // dispatch(setHovered({object: null, group: false}))
    }

    function onClick(e){
        e.stopPropagation()
        dispatch(setSelectedId(data.id))
      }

      React.useEffect(() => {
        if(object.current){
          let newCheckpoints = checkpoints.durations.slice(checkpoints.current)
          let positions = data.positions.slice(checkpoints.current)
          let visibles = data.visible.slice(checkpoints.current)
          let transitions = checkpoints.transitions.slice(checkpoints.current)

          tweenGroup.removeAll()
          let positionAnimations = []

          setPositionAnimations(null)
          setVisibleAnimations(null)
          if(newCheckpoints.length > 1){
            for(let i = 0; i < newCheckpoints.length - 1; i++){
              let transition = transitions[i].split('.')
              let positionStart = {...positions[i]}
              let positionEnd = {...positions[i + 1]}

              let positionTween = new TWEEN.Tween(positionStart, tweenGroup).to(positionEnd, newCheckpoints[i + 1] * 1000)
              .easing(TWEEN.Easing[transition[0]][transition[1]])
              .onUpdate(
                () => {
                  object.current.position.set(positionStart.x, positionStart.y, positionStart.z)
                }
              ).onComplete(() => {
                if(i === newCheckpoints.length - 2){
                  object.current.position.set(positions[0].x, positions[0].y, positions[0].z)
                  if(!visibles[0]){
                    object.current.visible = false
                    object.current.children[0].material.opacity = 0
                  }
                }
              })

              if(!visibles[i] && visibles[i + 1]){
                let delay = newCheckpoints.slice(1, i + 2).reduce((a,b) => a + b, 0) * 1000
  
                let opacity = {
                  value: 0
                }
                var visibleTween = new TWEEN.Tween(opacity, tweenGroup).to({value: 1}, 2000).onUpdate(() => {
                  object.current.children[0].material.opacity = opacity.value
                })
                .onStart(() => {
                  object.current.visible = true
                })
                .delay(delay)
              }

      
              if(positionAnimations.length > 0){
                positionAnimations[i - 1].chain(positionTween)
              } 
              positionAnimations.push(positionTween)
            }
            setVisibleAnimations(visibleTween)
            setPositionAnimations(positionAnimations)
          }
        }
    
      }, [object.current, data, checkpoints])
  
      React.useEffect(() => {
        if(object.current && !playback && !recording){
          if(positionAnimations) positionAnimations[0].stop()
          if(visibleAnimations) visibleAnimations.stop()
          object.current.position.set(data.positions[checkpoints.current].x, data.positions[checkpoints.current].y, data.positions[checkpoints.current].z)
          object.current.visible = data.visible[checkpoints.current]
          object.current.children[0].material.opacity = data.visible[checkpoints.current] ? 1 : 0
        }
        else if(playback || recording){
          time.current = TWEEN.now()
          if(positionAnimations) positionAnimations[0].start()
          if(visibleAnimations) visibleAnimations.start()
        }
      }, [playback, recording, checkpoints, positionAnimations, visibleAnimations, object.current])

    React.useEffect(() => {
        const img = new Image();
        img.onload = function() {
          setDisplayedImage(
              <group
              scale={[data.scale * this.width / 50, data.scale * this.height / 50, 1]}
              visible={data.visible[checkpoints.current]}
              ref={object} position={[data.positions[checkpoints.current].x, data.positions[checkpoints.current].y, data.positions[checkpoints.current].z]} userData={{
                setPosition: (position) => {
                dispatch(
                  setClassParam({
                    class: 'images',
                    id,
                    position,
                  }))
              }}}>
                <sprite name={'sprite'} ref={spriteRef} onClick={data.visible[checkpoints.current] ? onClick : null} onPointerOver={data.visible[checkpoints.current] ? onHover : null} >
                    <spriteMaterial attach="material" transparent map={texture} depthWrite={false} onUpdate={(s) => s.opacity = 1}/>
              </sprite>
              </group>
            )
          }
        img.src = data.img
    }, [data, texture, checkpoints.current, playback])

    return displayedImage
}